import { LabeledInput, LabeledSelect, SearchIcon } from '@commonsku/styles';
import React, { useState } from 'react';

const KeywordsInput = ({ value, onChange }) => {
  const height = '2.4375rem';
  const iconStyles = {
    height,
    lineHeight: height,
    borderRadius: 3,
    textAlign: 'center',
    position: 'absolute',
    fontSize: '1em',
    top: '24px',
    margin: '0px 5px 0px 8px',
  };
  return <div style={{ position: 'relative' }}>
    <div className="search-box" style={{
      display: 'inline-block', width: '307px',
    }}>
      <SearchIcon style={iconStyles} color='rgb(194, 219, 226)' />
      <LabeledInput
        label='Product'
        style={{ paddingLeft: height, fontWeight: 400, marginBottom: 0 }}
        type='text'
        placeholder='Search'
        value={value}
        onChange={onChange}
      />
    </div>
  </div>;
};


export default function CollectionSearch({ suppliers, onSelectSuppliers, onChangeKeyword }) {
  const suppliersOptions = suppliers.map(supplier => ({ value: supplier.supplier_id, label: supplier.supplier_name }))

  const [value, setValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([])
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onSelectSuppliers(selectedOptions.map(option => option.value));
  }

  const handleValueChange = (event) => {
    setValue(event.target.value);
    onChangeKeyword(event.target.value);
  }
  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <KeywordsInput value={value} onChange={handleValueChange} />
      <LabeledSelect
        parentStyle={{ flexGrow: 1 }}
        labelStyle={{ height: 24 }}
        label='Suppliers'
        placeholder='All Suppliers'
        isMulti
        isSearchable
        options={suppliersOptions}
        onChange={handleChange}
        value={selectedOptions}
      />
    </div >
  )
}
