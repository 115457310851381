import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAbsoluteUrl } from '../utils';

import { createSelectCollectionSupplierPopup } from '../actions/popup';
import { shuffle } from '../utils';

const MAX_SUPPLIERS = 7;

class CollectionSuppliers extends Component {

  constructor(props) {
    super(props);

    const spotlighted_suppliers = shuffle(props.suppliers.filter(s => s.spotlighted > 0));
    const unspotlighted_suppliers = props.suppliers.filter(s => s.spotlighted == 0).sort((a, b) => b.weight - a.weight);
    this.state = {
      suppliers: this.getSupplierList(spotlighted_suppliers.concat(unspotlighted_suppliers), props.supplier_id),
      supplier_id: props.supplier_id
    };

    this.onToggleSupplier = this.onToggleSupplier.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.supplier_id !== this.props.supplier_id && nextProps.supplier_id !== this.state.supplier_id) {
      this.onToggleSupplier(nextProps.supplier_id);
    }
  }

  getSupplierList(suppliers, supplier_id) {
    if (!supplier_id) {
      return suppliers;
    }
    const index = suppliers.map(s => s.supplier_id).indexOf(supplier_id);
    if (index < MAX_SUPPLIERS) {
      return suppliers;
    }
    return [suppliers[index]].concat(suppliers.slice(0, index)).concat(suppliers.slice(index + 1));
  }

  onToggleSupplier(supplier_id) {
    const { onSelectSupplier } = this.props;

    if (supplier_id === this.state.supplier_id) {
      this.setState({ supplier_id: '' }, () => onSelectSupplier(''));
    } else {
      this.setState((state, props) => {
        const suppliers = this.getSupplierList(state.suppliers, supplier_id);
        return {
          supplier_id,
          suppliers
        };
      }, () => onSelectSupplier(supplier_id));
    }
  }

  render() {
    const { className, style, onSelectExtraSupplier } = this.props;
    const { suppliers, supplier_id } = this.state;

    const company_icon_style = {
      width: '100px',
      height: '100px',
      float: 'left',
      borderRadius: '50%',
      background: 'white',
      margin: '1rem',
      transition: 'all .5s',
      cursor: 'pointer',
      overflow: 'hidden'
    };

    const unselected_style = { ...company_icon_style, border: '4px solid #76BBD1' };
    const selected_style = { ...company_icon_style, border: '7px solid #e95888' };

    return (
      <div className={className} style={style}>
        {suppliers.slice(0, MAX_SUPPLIERS).map(s =>
          <div key={s.supplier_id} style={supplier_id === s.supplier_id ? selected_style : unselected_style}>
            <img style={{ top: '50%', transform: 'translateY(-50%)', position: 'relative', margin: 'auto' }} src={getAbsoluteUrl(s.supplier_img)} onClick={e => this.onToggleSupplier(s.supplier_id)} alt={s.supplier_name} title={s.supplier_name} />
          </div>
        )}
        {suppliers.length === MAX_SUPPLIERS + 1 ?
          <div style={supplier_id === suppliers[MAX_SUPPLIERS].supplier_id ? selected_style : unselected_style}>
            <img style={{ top: '50%', transform: 'translateY(-50%)', position: 'relative', margin: 'auto' }} src={getAbsoluteUrl(suppliers[MAX_SUPPLIERS].supplier_img)} onClick={e => this.onToggleSupplier(suppliers[MAX_SUPPLIERS].supplier_id)} alt={suppliers[MAX_SUPPLIERS].supplier_name} title={suppliers[MAX_SUPPLIERS].supplier_name} />
          </div> : null}
        {suppliers.length > MAX_SUPPLIERS + 1 ?
          <div style={{ ...unselected_style, textAlign: 'center', color: '#76BBD1'}} onClick={e => onSelectExtraSupplier()}>
            <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', lineHeight: 1.2 }}>
              <span style={{ fontWeight: 'bold' }}>{suppliers.length - MAX_SUPPLIERS}</span>
              <br />
              <span>others</span>
            </div>
          </div>
        : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectExtraSupplier: () => dispatch(createSelectCollectionSupplierPopup(ownProps.suppliers))
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionSuppliers);
