import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createPath } from 'react-router-dom';

import CollectionFilter from './CollectionFilter';
import CollectionBadge from './CollectionBadge';
import Size from './ScreenSize';

import { oauth } from '../utils';
import { withRouter } from './helpers';
import { findIndex, isEmpty, debounce } from 'lodash';
import NewProduct from './NewProduct';
import ProductChevronPopup from './product/ProductChevronPopup';
import { createCopyToProjectPopup } from '../actions/popup';
import { createAddTemp, createClearTemp } from '../actions/temp';
import { H2, Loading, Text } from '@commonsku/styles';
import { createLoadClientList } from '../actions/client';
import { createLoadEventTypes } from '../actions';

const PAGE_SIZE = 24;
const PAGE_SCROLL_BUFFER = 10;

class CollectionList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search_terms: {},
      page: 0,
      finished: false,
      loading: true,
      collections: [],
      items: [],
      elementTop: 0
    };

    this.onSearchResult = this.onSearchResult.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onUpdateQuery = this.onUpdateQuery.bind(this);
    this.debouncedOnSearch = debounce(this.onSearch.bind(this), 300);
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentDidMount() {
    if (this.root) {
      this.setState({ elementTop: this.root.offsetTop });
    }
  }

  onUpdateQuery(queryLocation) {
    const { navigate } = this.props;
    navigate(createPath(queryLocation));
  }

  onSearchResult(page, collections = [], search_terms, items = []) {
    const finished = !isEmpty(search_terms.keyword) ? items.length < PAGE_SIZE : collections.length < PAGE_SIZE;

    this.setState({
      search_terms,
      page,
      finished,
      collections: 0 === page ? collections : this.state.collections.concat(collections),
      items: 0 === page ? items : this.state.items.concat(items)
    });
    if (this.root) {
      this.root.addEventListener('scroll', this.handleScroll);
    }
  }

  onSearch(page, search_terms) {
    const resetPage = page === 0 ? { items: [], collections: [] } : {};
    this.setState({ loading: true, ...resetPage });

    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();
    const { signal } = this.abortController;

    const data = Object.assign({
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    }, search_terms);
    oauth('GET', 'collection' + (search_terms?.keyword ? '/items' : ''), data, null, { signal }, null, false)
      .then(
        ({ json }) => {
          this.onSearchResult(page, json.collections, search_terms, json.items);
        }
      )
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error('Fetch error:', error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleScroll(e) {
    if ((this.root.scrollHeight - this.root.scrollTop) <= this.root.clientHeight + PAGE_SCROLL_BUFFER) {
      this.root.removeEventListener('scroll', this.handleScroll);
      if (!this.state.finished) {
        this.onSearch(this.state.page + 1, this.state.search_terms);
      }
    }
  }

  onClickProductAction(item) {
    this.props.onCopyAddToProjectPopup(item);
  }

  renderCollections() {
    if (!isEmpty(this.state.collections)) {
      return this.state.collections.map((collection, index, collections) => this.renderCollection(collection, index, collections));
    } else if (!this.state.loading) {
      return <div style={{ textAlign: 'center', padding: '77.5px 0' }}>
        <H2 style={{
          fontSize: 24,
          lineHeight: '40px',
          fontWeight: 600,
          fontFamily: 'skufont-regular'
        }}>We couldn’t find any collections that match your search.</H2>
      </div>;
    }
  }

  renderCollection(collection, index, collections) {
    const { identity } = this.props;
    const last = index === collections.length - 1;
    const onClick = (identity.company_id === collection.supplier_id) ?
      e => { window.open(`/collection/${collection.form_number}`); } :
      e => { window.open(`/collection/${collection.order_id}`); };
    const smallClear =  index % 2 === 0 ? <Size.SmallOnly key={`clearsmall-${index}`} style={{ clear: 'both' }} /> : null;
    const style = 'Published' !== collection.status_name || Date.parse(collection.date_expiry) < Date.now() ? { cursor: 'pointer', opacity: 0.5 } : { cursor: 'pointer' };
    return [
      index % 2 === 0 ? <Size.SmallOnly key={`clearsmall-${index}`} style={{ clear: 'both' }} /> : null,
      index % 3 === 0 ? <Size.MediumOnly key={`clearmedium-${index}`} style={{ clear: 'both' }} /> : null,
      index % 4 === 0 ? <Size.LargeOnly key={`clearlarge-${index}`} style={{ clear: 'both' }} /> : null,
      <div key={collection.order_id} className={'small-6 medium-4 large-3 columns' + (last ? ' end' : '')} style={style} onClick={onClick}>
        <CollectionBadge collection={collection} showSupplierDetails={identity.company_id === collection.supplier_id} />
      </div>
    ];
  }

  renderItems() {
    if (!isEmpty(this.state.items)) {
      return <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap', padding: '0 10px' }}>{this.state.items?.map((item) => this.renderItem(item))}</div>;
    } else if (!this.state.loading) {
      return <div style={{ textAlign: 'center', padding: '77.5px 0' }}>
        <H2 style={{
          fontSize: 24,
          lineHeight: '40px',
          fontWeight: 600,
          fontFamily: 'skufont-regular'
        }}>We couldn’t find any products in collections that match your search.</H2>
        <Text style={{
          fontSize: 18,
          lineHeight: '28px',
          fontWeight: 400,
          fontFamily: 'skufont-regular'
        }}>Try a different search, or use <a href='/products.php'  >product search</a> to search for products outside of collections.</Text>
      </div>;
    }
  }

  renderItem(item) {
    return <NewProduct
      key={item.item_id}
      product={item}
      loading={false}
      loaded={false}
      type="others"
      title={item.item_name}
      description={item.division_name}
      subtitle={item.job_name ? item.job_name : `Collection ${item.job_number}`}
      onClickProduct={(item) => {
        this.setState({ selectedItem: item });
      }}
      onSubTitleClick={() => window.open(`/collection/${item.order_id}`)}
      onClickProductAction={() => {
        this.onClickProductAction(item);
      }}
    />;
  }

  renderLoading() {
    return (
      <div className="row small-12 columns collapse" style={{ textAlign: 'center' }}>
        <Loading />
      </div>
    );
  }

  render() {
    const { company_id, style, pathname, baseQuery } = this.props;
    const { loading, elementTop, selectedItem, items } = this.state;

    const rootStyle = {
      padding: 0,
      height: `calc(100vh - ${elementTop}px)`,
      overflowY: 'auto',
      ...style
    };

    const queryParams = ['themes'];
    if (company_id) {
        queryParams.push('include_expired');
        queryParams.push('status_id');
    } else {
        queryParams.push('supplier_id');
    }

    const onChangeFilter = terms => {
      const query = Object.keys(terms).filter(t => queryParams.includes(t)).reduce((o, k) => ({ ...o, [k]: terms[k] }), {});
      if (baseQuery?.has('id')) {
        query.id = baseQuery.get('id');
      }
      if (baseQuery?.has('division_id')) {
        query.division_id = baseQuery.get('division_id');
      }

      let queryStr = "";
      for (let key in query) {
          if (queryStr != "") {
              queryStr += "&";
          }

          let value = query[key];
          if (Array.isArray(value)) {
            for (let y in value) {
              queryStr += "&" + key + "=" + encodeURIComponent(value[y]);
            }
        } else {
            queryStr += key + "=" + encodeURIComponent(value);
          }
      }
      const queryLocation = {
        pathname,
        search: queryStr
      };

      this.onUpdateQuery(queryLocation);
      this.debouncedOnSearch(0, terms);
    };
    return (
      <div ref={ref => this.root = ref} className="row small-12 columns" style={rootStyle}>
        <CollectionFilter key="filter" company_id={company_id} onChangeFilter={onChangeFilter} />
        <div className="row">
          {
            !isEmpty(this.state.search_terms.keyword) ? this.renderItems() : this.renderCollections()
          }
        </div>
        {loading && this.renderLoading()}
        {isEmpty(selectedItem) ? null : <ProductChevronPopup
          products={items}
          productIndex={findIndex(items, selectedItem)}
          isSelected={false}
          onClose={() => this.setState({
            selectedItem: false
          })}
          isEnd={this.state.finished && findIndex(items, selectedItem) === items.length - 1}
          onClickProductAction={() => this.onClickProductAction(selectedItem)}
        />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  pathname: ownProps.location.pathname,
  baseQuery: new URLSearchParams(ownProps.location.search),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCopyAddToProjectPopup: (item) => {
      dispatch(createClearTemp());
      dispatch(createAddTemp(item.item_id, { item_id: item.item_id, item_name: item.item_name, total_quantity: 1 }))
      dispatch(createCopyToProjectPopup(item.order_id, [item]));
      dispatch(createLoadClientList());
      dispatch(createLoadEventTypes());
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionList));
