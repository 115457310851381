import React, { Component } from 'react';

const MAX_THEMES = 15;

class CollectionThemes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showExtraThemes: false,
    };

    this.onToggleTheme = this.onToggleTheme.bind(this);
    this.onToggleExtraThemes = this.onToggleExtraThemes.bind(this);
    this.handleClickElsewhere = this.handleClickElsewhere.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickElsewhere, false);
    if (this.extraThemes) {
      const bounds = this.extraThemes.getClientRects()[0];
      this.setState({ left: bounds.right - 190, top: bounds.bottom + 5 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickElsewhere, false);
  }

  handleClickElsewhere(e) {
    if (e.target !== this.popup && !this.popup.contains(e.target) && (!this.extraThemes || (e.target !== this.extraThemes && !this.extraThemes.contains(e.target)))) {
      this.setState({ showExtraThemes: false });
    }
  }

  onToggleExtraThemes(e) {
    this.setState(state => {
      if (state.showExtraThemes) {
        return { showExtraThemes: false };
      } else if (this.extraThemes) {
        const bounds = this.extraThemes.getClientRects()[0];
        return { showExtraThemes: true, left: bounds.right - 190, top: bounds.bottom + 5 };
      }
    });
  }

  onToggleTheme(theme_id) {
    const { selected_themes } = this.props;
    if (selected_themes[theme_id]) {
      this.props.onDeleteCollectionTheme(selected_themes[theme_id]);
    } else {
      this.props.onAddCollectionTheme(theme_id);
    }
    this.setState(state => ({ showExtraThemes: !state.showExtraThemes }));
  }

  render() {
    const { className, style, themes, selected_themes, disabled } = this.props;
    const { showExtraThemes, left, top } = this.state;

    const extraThemesStyle = {
      position: 'absolute',
      width: '200px',
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      left,
      top
    };

    const checkStyle = {
      display: 'inline-block',
      width: '1rem',
      marginRight: '0.5rem'
    };

    const extraThemeSelected = themes.slice(MAX_THEMES).reduce((s, t) => s || selected_themes[t.theme_id], false);
    return (
      <div className={className} style={style}>
        {themes.slice(0, MAX_THEMES).map(t =>
          <button
            key={t.theme_id}
            className="button"
            onClick={e => this.onToggleTheme(t.theme_id)}
            style={{ marginRight: '0.5rem', backgroundColor: selected_themes[t.theme_id] ? '#e95888' : '#3c505a' }}
            disabled={disabled}
          >{t.theme_name}</button>
        )}
        {themes.length === MAX_THEMES + 1 ?
          <button
            className="button"
            onClick={e => this.onToggleTheme(themes[MAX_THEMES].theme_id)}
            style={{ marginRight: '0.5rem', backgroundColor: selected_themes[themes[MAX_THEMES].theme_id] ? '#e95888' : '#3c505a' }}
            disabled={disabled}
          >{themes[MAX_THEMES].theme_name}</button> :
          null}
        {themes.length > MAX_THEMES + 1 ?
          <button
            ref={ref => this.extraThemes = ref}
            className="button"
            style={{ marginRight: '0.5rem', backgroundColor: extraThemeSelected ? '#e95888' : '#3c505a' }}
            disabled={disabled}
            onClick={this.onToggleExtraThemes}
          >{themes.length - MAX_THEMES} others</button> :
          null}
        <div ref={ref => this.popup = ref} style={{ ...extraThemesStyle, display: showExtraThemes ? 'inline-block' : 'none' }}>
          <ul style={{ listStyleType: 'none', margin: 0 }}>
            {themes.slice(MAX_THEMES).map(t =>
              <li
                key={t.theme_id}
                onClick={e => this.onToggleTheme(t.theme_id)}
                style={{ margin: '0.5rem', cursor: 'pointer' }}
              >
                {selected_themes[t.theme_id] ? <i className="fi-check" style={checkStyle}></i> : <span style={checkStyle}>&nbsp;</span>}
                {t.theme_name}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default CollectionThemes;
